<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >

      <template v-slot:top>
        <div class="d-inline-flex align-center pl-2">
          <v-text-field
            v-model="name"
            class="pt-3"
            outlined
            clearable
            dense
            :label="$lang.labels.instanceId"
            data-cy="search-by-instanceId"
            style="min-width: 400px"
          ></v-text-field>
          <v-autocomplete
            v-model="type"
            hide-selected
            outlined
            dense
            clearable
            :items="formattedTypes"
            hide-no-data
            class="pl-2"
            hide-details
            :label="$lang.labels.type"
            :placeholder="$lang.actions.startTyping"
            prepend-inner-icon="mdi-format-list-bulleted"
            data-cy="search-by-instanceType"
          ></v-autocomplete>
          <div class="pb-1">
            <v-btn
              text
              color="primary"
              class="ml-2"
              @click="sendQuery()"
            ><v-icon>mdi-refresh</v-icon></v-btn>
          </div>
        </div>
      </template>

      <template v-slot:item.instanceId="{ item }">
        <a @click="name = item.instanceId"># {{ item.instanceId }}</a>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.started="{ item }">
        <div>{{ item.started | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.heartbeat="{ item }">
        <div>{{ item.heartbeat | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ $lang.status[item.status] }}</div>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { definitions } from '@/utils/definitions'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      options: {},
      totalItems: 0,
      headers: [
        { text: this.$lang.labels.instanceId, value: 'instanceId' },
        {
          text: this.$lang.labels.type,
          align: 'start',
          sortable: true,
          value: 'type'
        },
        { text: this.$lang.labels.started, value: 'started' },
        { text: this.$lang.labels.status, value: 'status' },
        { text: this.$lang.labels.heartbeat, value: 'heartbeat' }
      ],
      name: '',
      type: '',
      types: definitions.InternalJob.properties.type.enum,
      lock: true
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings']),
    formattedTypes () {
      return this.types.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  watch: {
    name: {
      handler(val) {
        if (val && val.length > 1) {
          this.sendQuery()
        } else if (!val || val.length < 1) {
          this.sendQuery()
        }
      }
    },
    type: {
      handler(val) {
        if (val && val.length > 1) {
          this.sendQuery()
        } else if (!val || val.length < 1) {
          this.sendQuery()
        }
      }
    },
    options: {
      handler () {
        this.sendQuery(false)
      },
      deep: true
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {
    if (this.$route && this.$route.query && (this.$route.query.name || this.$route.query.type || this.$route.query.instanceId)) {
      this.lock = true
      if (this.$route.query.type) {
        this.type = this.$route.query.type
      }

      if (this.$route.query.instanceId) {
        this.name = this.$route.query.instanceId
      }

      if (this.$route.query.name) {
        this.name = this.$route.query.name
      }

      setTimeout(() => this.loadPreFill(), 200)
    } else {
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (preFill && preFill.instancesList) {
          this.lock = true
          this.name = preFill.instancesList.name
          this.type = preFill.instancesList.type
          setTimeout(() => this.loadPreFill(), 200)
        }
      } else {
        this.lock = false
        this.sendQuery()
      }
    }
  },
  methods: {
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.instancesList) preFill.instancesList = {
          name: '',
          type: ''
        }

        preFill.instancesList.name = this.name
        preFill.instancesList.type = this.type

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      this.lock = false
      this.savePreFill()
      this.sendQuery()
    },
    async sendQuery(resetPage = true) {
      if (this.lock) return
      const obj = { options: this.options, instanceId: this.name  }

      if (this.type) {
        obj.type = this.type
      }

      obj.resetPage = resetPage

      this.$emit('fetchInternalJobs', obj)
      this.savePreFill()
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
