var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items.items,"item-key":"id","loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.items.meta.totalItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItemsGlobal
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-inline-flex align-center pl-2"},[_c('v-text-field',{staticClass:"pt-3",staticStyle:{"min-width":"400px"},attrs:{"outlined":"","clearable":"","dense":"","label":_vm.$lang.labels.instanceId,"data-cy":"search-by-instanceId"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-autocomplete',{staticClass:"pl-2",attrs:{"hide-selected":"","outlined":"","dense":"","clearable":"","items":_vm.formattedTypes,"hide-no-data":"","hide-details":"","label":_vm.$lang.labels.type,"placeholder":_vm.$lang.actions.startTyping,"prepend-inner-icon":"mdi-format-list-bulleted","data-cy":"search-by-instanceType"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('div',{staticClass:"pb-1"},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.sendQuery()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)]},proxy:true},{key:"item.instanceId",fn:function(ref){
    var item = ref.item;
return [_c('a',{on:{"click":function($event){_vm.name = item.instanceId}}},[_vm._v("# "+_vm._s(item.instanceId))])]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.id))])]}},{key:"item.started",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTimePrecise")(item.started)))])]}},{key:"item.heartbeat",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTimePrecise")(item.heartbeat)))])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$lang.status[item.type]))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$lang.status[item.status]))])]}}])}),_c('v-snackbar',{attrs:{"color":_vm.snackColor,"content-class":"text-center","top":""},model:{value:(_vm.snackShow),callback:function ($$v) {_vm.snackShow=$$v},expression:"snackShow"}},[_c('span',{staticClass:"color-accent-text"},[_vm._v(_vm._s(_vm.snackbarText))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }